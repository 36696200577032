var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-item bg",class:[
    {
      'v-one': (_vm.status === 1 || _vm.status === 3) && _vm.type === 1,
      'v-two': _vm.status === 2 && _vm.type === 1,
      'v-three': _vm.status === 1 && _vm.type === 2,
      'v-four': _vm.status === 2 && _vm.type === 2,
      'v-five': (_vm.status === 1 || _vm.status === 3) && _vm.type === 3,
      'v-six': _vm.status === 1 && _vm.type === 4,
    } ]},[_c('div',{staticClass:"item-left",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goDetail.apply(null, arguments)}}},[(_vm.isVip)?_c('img',{staticClass:"l-vip",attrs:{"src":require('@/assets/images/Public/vip-left.png'),"alt":""}}):_vm._e(),_c('div',{staticClass:"l-main"},[_c('div',{staticClass:"m-price",style:({ 'font-size': _vm.parValue.length >= 6 ? '0.32rem' : '0.48rem' })},[(Number(_vm.batchClass) !== 6)?_c('span',{style:({ 'font-size': _vm.parValue.length > 6 ? '0.2rem' : '0.24rem' })},[_vm._v("¥")]):_vm._e(),_vm._v(_vm._s(("" + (_vm.parValue.replace("￥", ""))))),(Number(_vm.batchClass) === 6)?_c('span',[_vm._v("折")]):_vm._e()]),_c('div',{staticClass:"m-hint",style:({
          'font-size': _vm.orderAmount.length > 6 ? '0.16rem' : '0.2rem',
        })},[_vm._v(" "+_vm._s(_vm.orderAmount)+" ")])])]),_c('div',{staticClass:"item-center",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goDetail.apply(null, arguments)}}},[_c('van-col',{staticClass:"c-label"},[_c('span',{staticClass:"iconfont icon-shop",staticStyle:{"font-size":"0.2rem","margin-right":"0.1rem"}}),_vm._v(" 仅"+_vm._s(_vm.shopName)+"可用")]),_c('van-col',{staticClass:"c-name"},[_vm._v(_vm._s(_vm.couponName))]),_c('van-col',{staticClass:"t-one"},[_vm._v(" "+_vm._s(_vm.effectiveDate)+" ")])],1),_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"r-main",class:[{ 'm-one': _vm.type === 1 || _vm.type === 3, 'm-two': _vm.type === 2 }]},[(_vm.type === 1 || _vm.type === 3)?[(_vm.btnName)?_c('van-col',{staticClass:"m-btn",class:[{ 'no-count': _vm.status === 2, splend: _vm.status === 3 }],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.btnName)+" ")]):_vm._e(),(_vm.quantity)?_c('div',{staticClass:"m-b-num"},[_vm._v(_vm._s(_vm.quantity))]):_vm._e()]:_vm._e(),((_vm.type === 2 || _vm.type === 4) && _vm.status == 1)?[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.4rem","height":"0.4rem","round":"","src":_vm.check
              ? require("@/assets/images/Tickets/check.png")
              : require("@/assets/images/Tickets/uncheck.png")},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick.apply(null, arguments)}}})]:_vm._e()],2)]),(_vm.status === 2 && _vm.type === 2)?_c('div',{staticClass:"footer"},[_c('van-image',{staticStyle:{"margin-right":"0.1rem"},attrs:{"fit":_vm.imageFit,"width":"0.24rem","height":"0.24rem","round":"","src":require("@/assets/images/Tickets/t1.png")}}),_c('span',[_vm._v("本场演出不支持使用优惠券")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }