






















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Swipe, SwipeItem } from "vant";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";

@Component({
  components: {
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
})
export default class Wonderful extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public WonderfulList!: string;
  @Prop({
    type: String,
    default: "",
  })
  public titleColor!: string;
  mounted(): void {
    this.initSwiper();
  }
  initSwiper(): void {
    new Swiper(".swiper1", {
      slidesPerView: "auto",
      spaceBetween: 10,
      slidesOffsetAfter: 30,
      autoplay: false,
      loop: false,
      on: {
        // click: function (swiper: any, event: any) {
        //   console.log(77, vm.num, 88, event);
        // },
      },
    });
  }
}
