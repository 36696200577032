









































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import LinkCenter from "./components/LinkCenter.vue";
import { Dialog, Image } from "vant";
@Component({
  components: {
    LinkCenter,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class LinkCoupon extends Mixins(Mixin) {
  public id: string | (string | null)[] = "";
  public couponList: Array<cms.CouponDetailDto> = [];
  public linkInfo: cms.LinkActivityCouponResponse = {};
  public showRule = false;
  public showOver = false;
  created(): void {
    this.updateData();
  }
  updateData(): void {
    this.id = this.$route.query.id;
    this.getLinkCoupons();
    document.addEventListener("visibilitychange", this.refreshPage);
  }
  refreshPage(): void {
    if (document.visibilityState == "visible") {
      let ua = this.getBrowserEnvironment();
      if (ua.ios) {
        this.getLinkCoupons();
      }
    }
  }
  /* 链接活动信息*/
  getLinkCoupons(): void {
    this.$api.cmsApi.lucky.getLinkActivity(this.id as string, ({ data }) => {
      this.linkInfo = data;
      window.document.title = data.activityName
        ? data.activityName
        : "爱演出 爱生活-保利票务";
      /*  活动待上架/已下架/已结束 */
      if (data.activityState === "0" || data.activityState === "2") {
        this.showOver = true;
      }
    });
  }
  beforeDestroy(): void {
    document.removeEventListener("visibilitychange", this.refreshPage);
  }
}
