


















































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import ProButton from "@/views/ProjectManagement/components/ProButton.vue";
import MallItem from "@/views/ProjectManagement/components/MallItem.vue";
import { List, Image, Row } from "vant";
import WaterfallLayout from "@/views/Mall/Details/waterfallLayout.vue";
@Component({
  components: {
    ProButton,
    MallItem,
    [List.name]: List,
    [Row.name]: Row,
    [Image.name]: Image,
    WaterfallLayout,
  },
})
export default class onlyMall extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: false,
  })
  public contentType!: boolean;
  recommendInfo = { upward: false };
  pages: number | undefined = 1; //总页数
  page = 1;
  size = 1000;
  otherList: Array<good.CommodityDto> = [];
  commodities: Array<good.CommodityDto> = [];
  mounted(): void {
    if (this.SpecialManagementModule.subjectCommodity) {
      this.getMallData();
    }
  }
  goMallDetails(itemInfo: good.CommodityDto): void {
    let channel = this.ChannelModule.channel;
    let theaterId = this.TheaterModule.theaterId;
    this.getViewMall(channel, theaterId, itemInfo.commodityInfoId);
  }
  getMallData(): void {
    let data = {
      categoryId: this.SpecialManagementModule.commodityId,
      keyword: this.SpecialManagementModule.commodityKeywords,
      subjectCommodity: this.SpecialManagementModule.subjectCommodity as
        | "COMMODITY_CLASSIFY"
        | "COMMODITY"
        | "COMMODITY_KEYWORD",
      page: this.page,
      size: this.size,
    };
    this.$api.goodApi.commodity.getSubjectCommodities(data, ({ data }) => {
      this.pages = data.pages;
      if (data.records) {
        data.records.forEach((value: good.CommodityDto) => {
          let isMember = false;
          if (value.lowestMemberPrices) {
            isMember = value.lowestMemberPrices.some((item) => {
              return item.memberGradeId === this.UserModule.memberLevelId;
            });
            if (isMember) {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == this.UserModule.memberLevelId;
                }
              );
            } else {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == "0";
                }
              );
            }
          }
        });
        this.commodities = data.records!;
        this.otherList = data.records!.filter((item, index) => {
          return index < 10;
        });
      }
    });
  }
}
