









































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import ResultList from "@/components/showProList.vue";
import ProButton from "@/views/ProjectManagement/components/ProButton.vue";
import MallItem from "@/views/ProjectManagement/components/MallItem.vue";
import { List, Image, Row } from "vant";
import ShowList from "@/views/ProjectManagement/components/ShowList.vue";
@Component({
  components: {
    ResultList,
    ProButton,
    MallItem,
    [List.name]: List,
    [Row.name]: Row,
    [Image.name]: Image,
    ShowList,
  },
})
export default class onlyShow extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: false,
  })
  public contentType!: boolean;
  showInfo = { upward: false };
  pages: number | undefined = 1; //总页数
  page = 1;
  size = 1000;
  otherList: Array<good.RecommendProductDto> = [];
  showList: Array<good.RecommendProductDto> = [];
  mounted(): void {
    if (this.SpecialManagementModule.subjectProduct) {
      this.getMallData();
    }
  }

  getMallData(): void {
    let data = {
      categoryId: this.SpecialManagementModule.productId,
      keyword: this.SpecialManagementModule.productKeywords,
      product: this.SpecialManagementModule.subjectProduct as
        | "PRODUCT_CLASSIFY"
        | "PRODUCT"
        | "PRODUCT_KEYWORD",
      page: this.page,
      size: this.size,
    };
    this.$api.goodApi.product.getSubjectProducts(data, ({ data }) => {
      console.log(333, data);
      if (data) {
        this.pages = data.pages;
        if (data.records) {
          this.showList = data.records;
          this.otherList = data.records.filter((item, index) => {
            return index < 10;
          });
        }
      }
    });
  }
  goProDetail(item: good.RecommendProductDto): void {
    let channel = this.ChannelModule.channel;
    let theaterId = this.TheaterModule.theaterId;
    this.getViewShow(
      channel,
      theaterId,
      item.tour,
      item.productId,
      item.tourId,
      item.plat
    );
  }
}
