import { render, staticRenderFns } from "./showProList.vue?vue&type=template&id=84da625e&scoped=true&"
import script from "./showProList.vue?vue&type=script&lang=ts&"
export * from "./showProList.vue?vue&type=script&lang=ts&"
import style0 from "./showProList.vue?vue&type=style&index=0&lang=less&"
import style1 from "./showProList.vue?vue&type=style&index=1&id=84da625e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84da625e",
  null
  
)

export default component.exports