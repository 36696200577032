var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"LinkCenter"},[_c('div',{staticClass:"list",style:({
      padding: _vm.CouponList.length > 3 ? '0.15rem 0 0.1rem 0' : '0.15rem 0',
    })},_vm._l((_vm.CouponList),function(item,index){return _c('div',{key:index,staticClass:"item",class:{
        shopItem: item.theaterId !== '40',
        platItem: item.theaterId === '40',
      }},[_c('LinkCouponItem',{class:{ 't-theater': item.theaterId !== '40' },attrs:{"type":3,"status":_vm.dealName(item.receivedType, item.myReceivedCount).status,"order-amount":_vm.dealAmount(item.orderAmountType, item.orderAmount),"shop-name":item.shopName,"coupon-name":item.couponName,"batch-class":item.batchClass,"effective-type":item.effectiveType,"effective-date":item.expireTimeStr,"is-vip":item.receiveUserType === 2 ? true : false,"btn-name":_vm.dealName(item.receivedType, item.myReceivedCount).name,"quantity":_vm.dealQuantity(
            item.receiveQuantityType,
            item.receivedType,
            item.myReceivedCount,
            item.receiveQuantity
          ),"par-value":String(item.parValue)},on:{"onClick":function($event){return _vm.onClick(item)},"goDetail":function($event){return _vm.goDetail(item)}}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }