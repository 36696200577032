










































































































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Image, Row, Col, Icon } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";
// import { keyword715, hasKeyword, has715Label } from "@/utils/label715";

@Component({
  components: {
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
  },
})
export default class showProList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public projectList!: Array<good.RecommendProductDto>;
  @Prop({
    type: String,
    default: "",
  })
  public keyword!: "";
  @Prop({
    type: Boolean,
    default: false,
  })
  public component!: boolean;
  @Watch("projectList", { deep: true })
  changeProjectList(val: Array<good.RecommendProductDto>): void {
    this.projectList = val;
  }
  @Prop({
    type: Number,
    default: () => {
      -1;
    },
  })
  public scrollTop!: number;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  categoryId!: string;

  // keyword715 = keyword715;

  lineheight(str?: string): string {
    let string = str || "";
    if (this.keyword) {
      let reg = new RegExp(this.keyword, "g");
      string = (str as string).replace(reg, "<em>" + this.keyword + "</em>");
    }
    return string;
  }
  goProDetail(item: good.RecommendProductDto): void {
    if (this.component) {
      this.$emit("goProDetail", item);
    } else {
      let theaterId = this.TheaterModule.theaterId;
      this.jsBridge(
        "goProDetail",
        {
          productId: item.productId,
          theaterId: theaterId,
          taskId: "",
        },
        () => {
          this.goToProjectDetail(item.productId, item.tourId, item.tour);
        }
      );
    }
    if (this.$route.name == "classify") {
      monitorEvent(
        "ItemList_ClickItem",
        "点击一个项目",
        item.productNameShort || ""
      ); // 埋点：分类页，点击一个项目
    }
  }
  // hasKeyword(name: string | undefined, keyword: string) {
  //     return hasKeyword(name, keyword);
  // }
  // has715Label(name: string | undefined) {
  //     return has715Label(name);
  // }
}
