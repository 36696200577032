



















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Image, Col, Icon } from "vant";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class SingleRow extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public imgList!: Array<cms.SubjectImgUrlDto>;
  goDetail(item: cms.SubjectImgUrlDto): void {
    this.goToJump(item.keyword, item.commonId, item.linkTypeEnum, item.url);
  }
}
